
import { defineComponent } from "vue";
import { Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
    name: "invite-friends-modal",
    components: {
        Field,
        Form,
    },
    props: ["raFormId"],
    setup() {
        return {};
    },
    data() {
        const store = useStore();

        const raForm = {
            file: null,
        }

        const raw_id = this.raFormId;

        return {
            raForm,
            store,
            raw_id
        };
    },
    methods: {
        onSubmit(values) {

            const data = {
                id: this.raw_id,
                file: values.raForm.file,
            };

            const formData = new FormData();
            for (const key in data) {
                if (data[key] === null || data[key] === undefined) continue;

                if (typeof data[key] === "object") {
                    if (Array.isArray(data[key])) {
                        for (const array in data[key]) {
                            if (data[key][array] instanceof File) {
                                formData.append(key, data[key][0]);
                                continue;
                            }

                            for (const object in data[key][array]) {
                                if (
                                    data[key][array][object] != null ||
                                    data[key][array][object] != ""
                                ) {
                                    formData.append(
                                        `${key}[${array}][${object}]`,
                                        data[key][array][object]
                                    );
                                }
                            }
                        }
                    } else {
                        for (const objKey in data[key]) {
                            formData.append(`${key}[${objKey}]`, data[key][objKey]);
                        }
                    }
                } else {
                    formData.append(key, data[key]);
                }
            }

            this.store.dispatch(Actions.UPLOAD_RA_FORM, formData).then(() => {
                const uploadResult = this.store.getters.getResponseRaFormData;

                if(uploadResult.success){
                    Swal.fire({
                        text: uploadResult.message,
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "OK",
                        customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(() => {
                        this.$router.go(0);
                    });
                } else {
                    Swal.fire({
                        text: uploadResult.message,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "OK",
                        customClass: {
                        confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(() => {
                        this.$router.go(0);
                    });
                }
            });
            // console.log(formData);
        },
    },
});
