
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import RaFormUploadFile from "@/components/modals/RaFormUploadFile.vue";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "customer-financial-statements",
  components: {
    RaFormUploadFile,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("RA Form List", ["Customer", "SME HD"]);
    });

    return {};
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    return {
      env: process.env.VUE_APP_ENVIRONMENNT,
      store,
      cstmr_id,
      raForm: [],
      raForms: [],
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_RA_FORMS, this.cstmr_id).then(() => {
        this.raForms = this.store.getters.getRaFormsData;
        console.log(this.raForms);
      });
    },
    getHumanDate(date) {
      console.log(date);
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
    onDeleteFile(data){
      Swal.fire({
        title: "Remove Result Document!",
        text: "Are you sure want to delete this document?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
      }).then((value) => {
        if (value.isConfirmed) {
          this.store.dispatch(Actions.DELETE_RA_FORM_DOCUMENT, data).then(() => {
            const deleteResult = this.store.getters.getResponseRaFormData;

            if(deleteResult.success){
                Swal.fire({
                    text: deleteResult.message,
                    icon: "success",
                    buttonsStyling: false,
                    confirmButtonText: "OK",
                    customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(() => {
                    this.$router.go(0);
                });
            } else {
                Swal.fire({
                    text: deleteResult.message,
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "OK",
                    customClass: {
                    confirmButton: "btn fw-bold btn-light-primary",
                    },
                }).then(() => {
                    this.$router.go(0);
                });
            }
            
          });
        }
      });
      return 1;
    }
  },
  async mounted() {
    this.init();
  },
});
